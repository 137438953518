import { Modal } from '@redq/reuse-modal';
import '@redq/reuse-modal/es/index.css';
import 'animate.css';
import { ResetCSS } from 'common/assets/css/style';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import { theme } from 'common/theme/saasAppCreative';
import Seo from 'components/seo';
import DocBanner from 'containers/SaasAppCreative/Banner/docBanner';
import Footer from 'containers/SaasAppCreative/Footer';
import Navbar from 'containers/SaasAppCreative/Navbar';
import {
  ContentWrapper,
  GlobalStyle,
} from 'containers/SaasAppCreative/saasAppCreative.style';
import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { graphql } from 'gatsby';

export default function Template({ data }) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;

  const content = (
    <React.Fragment>
      <div
        dangerouslySetInnerHTML={{ __html: html }}
        style={{ textAlign: 'left' }}
      />
    </React.Fragment>
  );

  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <Seo title="Terms and Conditions | Eazy Order Management" />
        {/*<Modal />*/}
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <DocBanner title={frontmatter.title} content={content} />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`;
